import Vue from "vue";

/*TITLE*/
document.title = "Terrazas de Peñíscola | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Terrazas de Peñíscola";
Vue.prototype.$subtitle = "Un balcón vanguardista hacia el mar con vistas privilegiadas donde vivir el Mediterráneo de otra manera";

/*INTRO*/
Vue.prototype.$promoter = "Terrazas de Peñíscola";
Vue.prototype.$introSubtitle = "Un balcón vanguardista hacia el mar con vistas privilegiadas donde vivir el Mediterráneo de otra manera";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-op02-forest-maple--20230124040136.jpg";
Vue.prototype.$image_kitchen = "cocina-op03-forest-fresno-revestimiento-bison-frentes-bison-encimera-krion--20230124040100.jpg";
Vue.prototype.$image_bath1 = "bano-ppal-op02-forest-maple-revestimiento-base-vela-smoked-revestimiento-decorativo-limit-vela-smoked--20230124040123.jpg";
Vue.prototype.$image_bath2 = "bano-sec-op02-forest-maple-revestimiento-base-matika-bone-revestimiento-decorativo-stripe-matika-bone--20230124040139.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "terraza-forest-fresno--20230124040106.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-op02-forest-maple--20230124040136.jpg",
  },
  {
    src: "salon-op01-forest-fresno--20230124040145.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-op03-forest-fresno-revestimiento-bison-frentes-bison-encimera-krion--20230124040100.jpg",
  },
  {
    src: "cocina-op02-forest-maple-revestimiento-blanco-frentes-blanco-encimera-krion--20230124040106.jpg",
  },
  {
    src: "cocina-op01-forest-fresno-revestimiento-frentes-roble-moon-encimera-krion--20230124040113.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "bano-ppal-op02-forest-maple-revestimiento-base-vela-smoked-revestimiento-decorativo-limit-vela-smoked--20230124040123.jpg",
  },
  {
    src: "bano-ppal-op01-forest-fresno-revestimiento-base-vela-smoked-revestimiento-decorativo-limit-vela-smoked--20230124040130.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "bano-sec-op02-forest-maple-revestimiento-base-matika-bone-revestimiento-decorativo-stripe-matika-bone--20230124040139.jpg",
  },
  {
    src: "bano-sec-op01-forest-fresno-revestimiento-base-matika-bone-revestimiento-decorativo-stripe-matika-bone--20230124040148.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "terraza-forest-fresno--20230124040106.jpg",
  },
  {
    src: "cam_1_post_hd_azul--20230124040101.jpg",
  },
  {
    src: "cam_2_post_hd_azul--20230124040107.jpg",
  },
  {
    src: "cam_3_post_hd_azul--20230124040118.jpg",
  },
  {
    src: "peniscola_post_hd--20230124040129.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/TERRAZAS_PENISCOLA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/9HZaR31TCTU";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20d'%20alacant%20peníscola",
    text: "Carrer d' Alacant, Peníscola",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avinguda%20d'espanya%2017%20peñíscola",
    text: "Avinguda d'Espanya, 17, Peñíscola",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:964489180",
    text: "964489180",
  },
  {
    icon: "mdi-email",
    link: "mailto:terrazaspeniscola@jbm.com.es",
    text: "terrazaspeniscola@jbm.com.es",
  },
];
